<template>
<div class="login-container">
  <el-dialog
    title=""
    :visible.sync="loginDialogVisible"
    :modal-append-to-body="false"
    @close="closedialogVisible"
    class="login-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center>
    <div class="login-box">
      <div class="login-title">登录蚂蚁写作，AI帮你写</div>
      <div class="login-cont">
<!--        <div class="login-item pc-show">-->
<!--          <div class="login-item-title">微信扫码登录</div>-->
<!--          <div class="login-img">-->
<!--            <div v-if="loginDetail.url">-->
<!--              <img :src="loginDetail.url" alt="">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="login-item">
          <div class="login-item-title">验证码登录</div>
          <div class="login-form">
            <el-form :model="form" :rules="rules" :label-position="'left'" ref="form">
              <el-form-item class="list-item" label="手机号:" prop="phone">
                <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item class="list-item" label="验证码:" prop="smsCode">
                <el-input v-model="form.smsCode" maxlength="6" placeholder="请输入验证码"></el-input>
                <el-button class="text-btn" type="text" :disabled="isCountDown" @click="sendCode">{{ countDownText }}</el-button>
              </el-form-item>
              <el-form-item class="list-item">
                <el-button type="primary" style="width: 100%" @click="submitForm">登 录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="login-tip">
        扫码注册成功后代表同意《
        <span @click="openDialog('蚂蚁写作用户协议','agree')">蚂蚁写作用户协议</span>
        》《
        <span @click="openDialog('隐私政策','privacy')">隐私政策</span>
        》</div>
    </div>
  </el-dialog>
  <el-dialog
    :visible.sync="agrVisible"
    :title="agrDialog.title"
    :modal-append-to-body="false"
    class="agr-dialog"
    center>
    <div class="agr-box">
      <div v-html="agrDialog.content"></div>
    </div>
  </el-dialog>
</div>
</template>
<script>
import agreement from '@/utils/agreement'
import PromotionApi from '@/api/promotion'
import UserPromotApi from "@/api/user"
import { EventBus } from "@/utils/eventBus"

export default {
  name: 'Login',
  data() {
    return {
      loginDialogVisible: false,
      loginDetail: '',
      shake: false,
      agrVisible: false,
      agrDialog: {
        title: '',
        content: ''
      },
      form: {
        phone: '',
        smsCode: ''
      },
      rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'string',pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 6, message: '验证码长度为6位', trigger: 'blur' }
        ]
      },
      countDown: 0,
      timerCode: null,
      windowWidth: document.body.clientWidth,
      isMobile: false
    }
  },
  created() {
    this.isMobile = this.windowWidth < 901;
  },
  watch: {
    // 监听页面宽度
    windowWidth(newWidth) {
      this.isMobile = newWidth < 901;
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth
      })()
    }
  },
  computed: {
    isCountDown() {
      return this.countDown > 0;
    },
    countDownText() {
      // console.log(this.isCountDown)
      return this.isCountDown ? `${this.countDown}s后重新发送` : '发送验证码';
    }
  },
  methods: {
    openLogin(){
      this.loginDialogVisible = true
      // if(this.shake === true){
      //   return
      // }
      // this.shake = true
      // if(this.isMobile){
      //   this.loginDialogVisible = true
      // }else {
      //   this.getLoginUrl()
      // }
    },
    closedialogVisible(){
      //关闭弹窗 并 清除定时器查询
      clearInterval(this.timerLogin)
      this.shake = false
      this.loginDialogVisible = false
    },
    // 获取登录二维码
    getLoginUrl(){
      let _this = this;
      PromotionApi.getLoginUrl().then(res => {
        // key 、 url
        _this.loginDetail = res.data
        _this.loginDialogVisible = true
        _this.getLoginStatus(_this.loginDetail.key)
      }).catch(() => {
      })
    },
    // 查询是否登录
    queryLogin(key){
      let _this = this;
      PromotionApi.queryLogin(key).then(res => {
        if(res.data.isLogin === true){
          _this.$message.success('登录成功')
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          _this.getUserDetailVo(res.data.token)
        }
      }).catch(() => {
      })
    },
    //定时器查询登录状态
    getLoginStatus(key){
      this.timerLogin = setInterval(() => {
        this.queryLogin(key)
      }, 3000);
    },
    // 查看协议弹窗
    openDialog(test,type){
      // console.log(agreement)
      this.agrDialog.title = test
      if(type === 'agree'){
        this.agrDialog.content = agreement.agree
      }else if(type === 'privacy'){
        this.agrDialog.content = agreement.privacy
      }
      this.agrVisible = true
    },
    // 登录成功获取用户信息
    getUserDetailVo(token){
      let _this = this
      UserPromotApi.getUserDetailVo(token).then(res => {
        localStorage.setItem('userSetInfo', JSON.stringify(res.data))
        // _this.$emit('userInfoSelf','userTrue')
        // 传递登录成功的消息
        EventBus.$emit("userLogin", 'userLoginTrue')
        // 关闭弹窗并清除定时器
        _this.closedialogVisible()
      }).catch(() => {
      })
    },
    // 发送验证码的请求
    sendCode() {
      let _this = this
      this.$refs.form.validateField('phone', (valid) => {
        if (!valid) {
          // 发送验证码的请求
          if (!_this.isCountDown){
            _this.postSms(this.form.phone)
          }
        } else {
          _this.$message.error('手机号不正确')
          return false;
        }
      })
    },
    // 验证码倒计时 定时器
    startCountDown() {
      if (this.timerCode) return;
      this.countDown = 60;
      this.timerCode = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1;
        } else {
          clearInterval(this.timerCode);
          this.timerCode = null;
        }
      }, 1000);
    },
    // 登录提交
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 验证码输入正确，执行提交操作
          // console.log('验证码输入正确，提交表单');
          this.postLoginByPhone()
        } else {
          // console.log('表单验证失败');
          return false;
        }
      });
    },
    // 登录
    postLoginByPhone(){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      UserPromotApi.postLoginByPhone(_this.form,key,time,infoMd5).then(res => {
        if(res.data.isLogin === true){
          _this.$message.success('登录成功')
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          _this.getUserDetailVo(res.data.token)
          setTimeout(()=>{
            clearInterval(_this.timerCode);
            _this.timerCode = null;
            _this.countDown = 0;
          },200)
        }
      }).catch(() => {
      })
    },
    // 获取验证码
    postSms(phone){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      UserPromotApi.postSms(phone,key,time,infoMd5).then(res => {
        _this.$message.success('验证码已发送')
        // 开启验证码定时器
        _this.startCountDown();
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang="less">
.login-container{
  .login-dialog{
    /deep/ .el-dialog{
      width: 520px;
      border-radius: 15px;
      background: linear-gradient(180deg, #ebf1fc, #fff);
      .el-dialog__header{
        border-bottom: 0;
        .el-dialog__close {
          font-size: 24px;
        }
      }
    }
  }

  .login-box{
    min-height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .login-title{
      height: 24px;
      line-height: 24px;
      margin-bottom: 30px;
      font-size: 17px;
      color: #3f4654;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .login-cont{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-evenly;
      .login-item{
        position: relative;
        .login-item-title{
          position: relative;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #101010;
        }
        .login-item-title::before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 90px;
          height: 8px;
          margin-left: -45px;
          background-color: rgba(42, 171, 255, 0.3);
        }
        .login-img{
          position: relative;
          border-radius: 15px;
          border: none;
          margin-top: 70px;
          >div{
            padding: 10px;
            border: 1px solid #e6e6e6;
            img{
              width: 124px;
              height: 124px;
              padding: 0;
              margin: 0;
              border: 0;
              background-color: #ffffff;
            }
          }
          >div:before,>div:after{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
          }
          >div:before{
            bottom: 0;
            left: 0;
            border-bottom: 2px solid #36b7e6;
            border-left: 2px solid #36b7e6;
          }
          >div:after{
            bottom: 0;
            right: 0;
            border-bottom: 2px solid #36b7e6;
            border-right: 2px solid #36b7e6;
          }
        }
        .login-img:before,.login-img:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
        }
        .login-img:before {
          top: 0;
          left: 0;
          border-top: 2px solid #36b7e6;
          border-left: 2px solid #36b7e6;
        }
        .login-img:after {
          top: 0;
          right: 0;
          border-top: 2px solid #36b7e6;
          border-right: 2px solid #36b7e6;
        }
        .login-form{
          width: 240px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          /deep/ .el-form{
            width: 100%;
            .list-item{
              position: relative;
              margin-bottom: 10px;
              .el-form-item__label{
                font-size: 12px !important;
              }
              .el-input__inner{
                font-size: 12px !important;
              }
              .text-btn{
                position: absolute;
                top: 40px;
                right: 10px;
                z-index: 9;
                span{
                  font-size: 12px !important;
                }
              }
            }
            .list-item:nth-child(2){
              margin-bottom: 30px;
            }
          }
        }
      }
      .login-item:first-child{
        height: 280px;
        //padding-right: 100px;
      }
      //.login-item:first-child::after{
      //  content: '';
      //  position: absolute;
      //  top: 0;
      //  right: 0;
      //  width: 1px;
      //  height: 100%;
      //  background-color: #e7e7e7;
      //}
      .pc-show{
        display: block;
      }
      .h5-show{
        display: none;
      }
    }
    .login-tip{
      margin-top: 60px;
      text-align: center;
      font-size: 12px;
      color: #8b9098;
      >span{
        font-size: 12px;
        cursor: pointer;
      }
      >span:hover{
        color: #6e83f7;
      }
    }
  }
}
.agr-dialog{
  /deep/ .el-dialog{
    width: 720px;
    height: 90vh;
    margin-top: 5vh !important;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    .el-dialog__close{
      font-size: 24px;
    }
    .el-dialog__body{
      height: calc(100% - 54px);
      padding: 0;
    }
  }
  .agr-box{
    width: 100%;
    height: 100%;
    overflow: auto;
    /deep/ .xunyun-box{
      text-align: justify;
      font-size: 14px;
      font-weight: 400;
      color: #141414;
      padding: 0 30px 60px 30px;
      .xunyun-title{
        font-size: 24px;
        font-weight: 600;
        color: #141414;
        margin: 50px 0;
        text-align: center;
        word-break: break-all;
      }
      p {
        margin-top: 12px;
        line-height: 28px;
        word-break: break-all;
      }
      .xunyun-xbt{
        font-size: 15px;
        font-weight: 600;
        color: #141414;
        margin-top: 40px;
        word-break: break-all;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .login-dialog {
    /deep/ .el-dialog{
      width: 90% !important;
      .el-dialog__close {
        font-size: 24px;
      }
    }
    .login-box {
      .login-cont{
        .login-item{

        }
        .pc-show{
          display: none;
        }
        .h5-show{
          display: block;
        }
      }
    }
  }
  .agr-dialog {
    /deep/ .el-dialog {
      width: 90% !important;
    }
    .agr-box{
      /deep/ .xunyun-box{
        font-size: 12px;
        padding: 0 15px 30px 15px;
        .xunyun-title{
          font-size: 16px;
          margin: 25px 0;
        }
        p {
          margin-top: 8px;
          line-height: 20px;
        }
        .xunyun-xbt{
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
